exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-biocombustiveis-index-js": () => import("./../../../src/templates/biocombustiveis/index.js" /* webpackChunkName: "component---src-templates-biocombustiveis-index-js" */),
  "component---src-templates-blogpost-blogpost-js": () => import("./../../../src/templates/blogpost/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-blogpost-js" */),
  "component---src-templates-caseitem-caseitem-js": () => import("./../../../src/templates/caseitem/caseitem.js" /* webpackChunkName: "component---src-templates-caseitem-caseitem-js" */),
  "component---src-templates-cases-index-js": () => import("./../../../src/templates/cases/index.js" /* webpackChunkName: "component---src-templates-cases-index-js" */),
  "component---src-templates-comercializacao-index-js": () => import("./../../../src/templates/Comercializacao/index.js" /* webpackChunkName: "component---src-templates-comercializacao-index-js" */),
  "component---src-templates-contato-contato-js": () => import("./../../../src/templates/Contato/Contato.js" /* webpackChunkName: "component---src-templates-contato-contato-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-migracao-index-js": () => import("./../../../src/templates/Migracao/index.js" /* webpackChunkName: "component---src-templates-migracao-index-js" */),
  "component---src-templates-selos-index-js": () => import("./../../../src/templates/selos/index.js" /* webpackChunkName: "component---src-templates-selos-index-js" */),
  "component---src-templates-servicos-index-js": () => import("./../../../src/templates/Servicos/index.js" /* webpackChunkName: "component---src-templates-servicos-index-js" */),
  "component---src-templates-sobre-index-js": () => import("./../../../src/templates/Sobre/index.js" /* webpackChunkName: "component---src-templates-sobre-index-js" */)
}

