import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
// import "@reach/dialog/styles.css"
import "@fontsource/museomoderno"
import "@fontsource/inter"
import "@fontsource/prompt"
import "@fontsource/montserrat"

export const wrapRootElement = ({ element }) => {
  return <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
}
